import styled from "styled-components";
import { SecondaryButton } from "../styles/buttons.styled";
import PlayerImage from "./Player/PlayerImage";
import { breakpoints, pxToRem } from "../styles/styleUtils";
import { useModal } from "src/components/Modal";
import EntryModal from "./SignIn/EntryModal";
import { getGameState, getLockedCategoryState } from "src/utils/game";
import CutWarning from "./CutWarning";
import { palette } from "../styles/styleUtils";
import { useSession } from "src/hooks/useSession";
import useClientContext from "src/hooks/useClientContext";
import SignInModal from "../components/SignIn/SignInModal";
import { emptyHeadshot } from "src/utils/game";
import { FONTS } from "../styles/styleUtils";
import { GAMESTATE, Round } from "../constants/interfaces/game";
import { useRosterQuery } from "src/hooks/api";
import { useEffect, useState } from "react";
import SwapIcon from "./SwapIcon";
import LockedCategory from "./LockedCategory";

interface CategoryContainerInterface {
  active?: boolean;
  inactive?: boolean;
  category?: string;
  isComingSoon?: boolean;
  disabled?: boolean;
  wildcardSwappingOpen?: boolean;
  selectedPlayer?:
    | {
        first_name: string;
        last_name: string;
        team: string;
        headshot_url: string;
        remote_id: string;
        round_stats: Round[];
        live: boolean;
      }
    | false;
}

interface CategoryCardInterface extends CategoryContainerInterface {
  title: string;
  onClick: (arg0: string) => void;
  areaLabel: string;
  category: string;
  src: string;
  selectedPlayer: {
    first_name: string;
    last_name: string;
    team: string;
    headshot_url: string;
    remote_id: string;
    round_stats: Round[];
    points: number;
    player_id: number;
    live: boolean | null;
  };
  selectedRound;
}

export default function CategoryCard(props: CategoryCardInterface) {
  const {
    title,
    active,
    areaLabel,
    onClick,
    selectedPlayer,
    selectedRound,
    inactive,
    isComingSoon,
    category,
    src,
    wildcardSwappingOpen,
  } = props;
  const { showModal } = useModal();
  const { isAuth } = useSession();
  const [{ entry }] = useClientContext();
  const [player, setPlayer] = useState(selectedPlayer);
  const [isWithdrawn, setIsWithdrawn] = useState(false);
  const [isCut, setIsCut] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const { data: game } = useRosterQuery();
  const gameState: string = getGameState(game);
  const disabled = category === "wildcard" && !wildcardSwappingOpen;
  const categoryState: string = getLockedCategoryState(game, category);
  const [roundId, setRoundId] = useState(null);
  const handleChoosePlayerClick = () => onClick(areaLabel);

  const getIsLocked = (player) => {
    if (categoryState === "locked" || player.live === true || hasStarted) {
      return true;
    } else return false;
  };

  const playerIsLocked = getIsLocked(player);

  function handleCategoryClick() {
    if (isLoggedIn && !entry && gameState === GAMESTATE.ROSTER_CREATION_OPEN) showModal(<EntryModal />);
    else if (isLoggedIn && entry && !player.live && categoryState !== "locked" && !hasStarted)
      handleChoosePlayerClick();
    else if (!isLoggedIn) showModal(<SignInModal origin={"Landing"} />);
  }

  useEffect(() => {
    const round_id = game?.rounds?.find((round) => round.round_number === selectedRound)?.id;
    setRoundId(round_id);
  }, [game, selectedRound]);

  useEffect(() => {
    setIsLoggedIn(isAuth ? true : false);
  }, [isAuth]);

  useEffect(() => {
    setIsWithdrawn(false);
    setPlayer(selectedPlayer);

    if (selectedPlayer) {
      selectedPlayer.round_stats.forEach((round) => {
        if (round.status == "withdrawn") setIsWithdrawn(true);
        else if (round.status == "cut") setIsCut(true);
        else if (round.started == true && selectedRound == round.round_number) setHasStarted(true);
      });
    }
  }, [selectedPlayer, selectedRound, player]);

  function getGolfersPointsForRound(round_id: number, player) {
    const pick = entry?.picks?.find((pick) => round_id === pick?.round_id && player?.player_id === pick?.player_id);
    if (pick?.has_started) return pick?.points;
    else return pick?.points || 0;
  }

  function getGolfersTotalPoints(player) {
    const picks = entry?.picks?.filter((pick) => player?.player_id === pick?.player_id);
    return picks?.reduce((total, pick) => total + pick?.points, 0);
  }

  return (
    <CategoryContainer
      disabled={disabled || playerIsLocked}
      onClick={!disabled ? () => handleCategoryClick() : null}
      selectedPlayer={player}
      inactive={inactive}
      active={active}
      isComingSoon={isComingSoon}
    >
      {playerIsLocked && category !== "wildcard" ? <LockedCategory /> : null}

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <CardTitle
          isTeamOf20={title === "Corebridge Financial Team"}
          inactive={inactive || isComingSoon || isWithdrawn || isCut}
        >
          {title}
        </CardTitle>
        {category === "wildcard" ? (
          <SwapIcon
            selectedPlayer={selectedPlayer}
            wildcardSwappingOpen={wildcardSwappingOpen}
            isLocked={playerIsLocked}
          />
        ) : null}
        {!player && (
          <CategoryImageContainer category={category} inactive={inactive || isComingSoon} id={category}>
            <img src={`/game-icons/${src}.png`} alt={`${title} icon`} />
          </CategoryImageContainer>
        )}
      </div>
      {player && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {isWithdrawn || isCut ? <CutWarning isCut={isCut} isWithdrawn={isWithdrawn} /> : null}
          <PlayerImageContainer isWithdrawn={isWithdrawn || isCut}>
            <PlayerImage headshotUrl={player?.headshot_url || emptyHeadshot()} />
          </PlayerImageContainer>
          <PlayerName isWithdrawn={isWithdrawn || isCut}>
            {player?.first_name} {player?.last_name}
          </PlayerName>
        </div>
      )}
      {isComingSoon && <ComingSoonText>Coming Soon</ComingSoonText>}
      {player && (isWithdrawn || isCut) && gameState === GAMESTATE.ROSTER_CREATION_OPEN ? (
        <StyledSecondaryButton>Swap Player</StyledSecondaryButton>
      ) : gameState !== GAMESTATE.ROSTER_CREATION_LOCKED && !player ? (
        <StyledSecondaryButton style={{ opacity: isComingSoon && "0.3" }}>Choose Player</StyledSecondaryButton>
      ) : (
        <PointsContainer active={active}>
          <div className="points">
            <h5>RD {selectedRound}:</h5> <p>{getGolfersPointsForRound(roundId, player)}</p>
          </div>
          <div className="points">
            <h5>TOT:</h5> <p>{getGolfersTotalPoints(player)}</p>
          </div>
        </PointsContainer>
      )}
    </CategoryContainer>
  );
}

const CategoryContainer = styled.div<CategoryContainerInterface>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.selectedPlayer ? "flex-start" : "space-between")};
  flex: 0 1 calc(20% - 1em);
  position: relative;
  padding: 15px;
  max-height: 300px;
  border-radius: 6px;
  box-shadow: 0 4px 36px rgba(0, 10, 22, 0.15);
  border: ${(props) => props.active && "4px solid #ffbe00"};
  align-items: center;
  margin-bottom: 20px;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};

  .player-locked-details {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    height: 60px;
    align-items: center;
    transform: translateY(10px);

    .tot {
      margin-right: 15px;
    }

    @media screen and (max-width: ${breakpoints.mobile}) {
      transform: translateY(0px);
    }

    .label {
      color: #8f8f8f;
    }
    .value {
      font-weight: bold;
      color: black;
    }
  }

  button {
    width: 70% !important;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: 11px;
      line-height: 13px;
    }
  }

  .selected {
    opacity: 0.3;
    cursor: initial;
  }

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.mobile}) {
    /* max-height: 270px; */
    flex: 0 1 calc(50% - 1em);
    &:last-child {
      margin: 10px auto;
    }
  }
`;

const CardTitle = styled.h3<{ inactive: boolean; isTeamOf20?: boolean }>`
  opacity: ${(props) => props.inactive && 0.3};
  text-transform: uppercase;
  text-align: center;
  font-size: 17px;
  line-height: 20px;
  transform: ${({ isTeamOf20 }) => isTeamOf20 && "translateY(-3px)"};
  margin: 0;
  max-width: ${({ isTeamOf20 }) => isTeamOf20 && "170px"};
  position: absolute;
  width: 88%;
  color: #af9157;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 15px;
  }

  img {
    height: 37px;
    transform: translateY(-7px);
  }
`;

const CategoryImageContainer = styled.div<CategoryContainerInterface>`
  margin-top: ${({ category }) => (category === "Corebridge" ? "45px" : "30px")};
  margin-bottom: 10px;
  opacity: ${(props) => props.inactive && 0.3};

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: ${({ category }) => (category === "Corebridge" ? "45px" : "40px")};
    margin-bottom: 20px;
  }

  // Non conventional... but the ask from PGA was to update the image for the womens version
  // And the provided image has no background so we have to make do with what we have
  &#pgaChampion {
    background-color: ${palette.primary1};
    border-radius: 100px;
    height: 125px;
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(20px);
    border: 2px solid ${palette.neutral1};
    -webkit-box-shadow: 2px 2px 8px 1px rgba(196, 196, 196, 1);
    -moz-box-shadow: 2px 2px 8px 1px rgba(196, 196, 196, 1);
    box-shadow: 2px 2px 8px 1px rgba(196, 196, 196, 1);

    @media (max-width: ${breakpoints.mobile}) {
      transform: translateY(12px);
      height: 75px;
      width: 75px;
    }

    img {
      height: 75px;
      width: 65px;

      @media (max-width: ${breakpoints.mobile}) {
        height: 40px;
        width: 35px;
      }
    }
  }

  img {
    height: ${({ category }) => (category === "Corebridge" ? "135px" : "165px")};
    width: ${({ category }) => (category === "Corebridge" ? "135px" : "165px")};

    @media (max-width: ${breakpoints.mobile}) {
      height: ${({ category }) => (category === "Corebridge" ? "92px" : "100px")};
      width: ${({ category }) => (category === "Corebridge" ? "90px" : "100px")};
    }
  }
`;

const PlayerImageContainer = styled.div<{ isWithdrawn: boolean }>`
  margin-bottom: 11px;
  margin-top: 60px;
  height: 120px;
  width: 120px;
  border-radius: 100px;
  border: 1px solid #af91574d;
  opacity: ${(props) => props.isWithdrawn && ".5"};

  @media (max-width: ${breakpoints.mobile}) {
    height: 98px;
    width: 98px;
  }
`;

const PlayerName = styled.h3<{ isWithdrawn: boolean }>`
  margin: 0 !important;
  text-align: center;
  font-family: ${FONTS.SERIF};
  opacity: ${(props) => props.isWithdrawn && ".5"};
  color: ${palette.primary1};
  font-size: 20px;
  line-height: 20px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  bottom: 15px;
  max-width: 200px;
  padding: 0;
  margin-top: 10px;

  @media (max-width: ${breakpoints.mobile}) {
    height: 35px;
  }
`;

const ComingSoonText = styled.p`
  position: absolute;
  font-size: ${pxToRem(22)};
  font-weight: 900;
  text-transform: uppercase;
  top: 50%;
  left: 50%;
  width: 90%;
  text-align: center;
  transform: translate(-50%, -50%);
`;

const PointsContainer = styled.div<CategoryContainerInterface>`
  display: ${(props) => (props.active ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  /* position: absolute; */
  margin-top: 10px;
  min-width: 100px;
  padding: 0;

  .points {
    display: flex;
    h5 {
      color: rgb(143, 143, 143);
      font-family: ${FONTS.NUNITO};
      font-size: 14px;
      font-weight: bold;
      height: 19px;
      text-transform: uppercase;
    }

    p {
      color: rgb(0, 0, 0);
      font-family: ${FONTS.NUNITO};
      font-size: 14px;
      font-weight: bold;
      height: 19px;
      letter-spacing: 0px;
      text-transform: uppercase;
      margin: 0 10px 0 5px;
    }
  }
`;
