import React, { FunctionComponent, useEffect, useState } from "react";
import Close from "../Icons/Close";
import styled from "styled-components";
import useApiFetch from "src/hooks/useApiFetch";
import { useRouter } from "next/router";
import { useGroupsMutation } from "src/hooks/useEntryMutation";
import { palette, pxToRem } from "../../styles/styleUtils";
import { breakpoints } from "../../styles/styleUtils";
import useClientContext, { setCurrentViewingGroup } from "../../hooks/useClientContext";
import useAnalytics from "../../hooks/useAnalytics";

const GroupListHead = styled.div`
  background-color: ${palette.primary1};
  color: ${palette.neutral1};
  display: flex;
  padding: 13px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  margin-bottom: 15px;

  h4 {
    font-size: ${pxToRem(15)};
    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${pxToRem(13)};
    }
  }

  #rank {
    width: 20%;
  }

  #group-name {
    width: 35%;
  }

  #user-count {
    width: 35%;
    text-align: center;
  }

  #leave {
    width: 10%;
  }
`;

const StyledGroupRow = styled.div`
  box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  -webkit-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  -moz-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 22px 15px;

  @media (hover: hover) {
    :hover:not(:active) {
      color: #222;
      background-color: #f2f4f7;
    }
  }

  p {
    font-weight: bold;
    text-transform: uppercase;
    align-self: center;
  }

  .rank {
    width: 20%;
  }

  .group-name {
    width: 35%;
  }

  .user-count {
    width: 35%;
    text-align: center;
  }

  .leave {
    width: 10%;
    text-align: right;
    padding-right: 20px;
  }
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  color: rgb(189, 48, 43);
`;

export interface Group {
  group_id?: number;
  group_rank?: number;
  group_name?: string;
  entry_count?: number;
  handleGroupLeave?: (groupsList: Group[]) => void;
}

interface GroupsProps {
  groups: Group[];
  handleGroupLeave?: (groupsList: Group[]) => void;
}

const GroupRow: FunctionComponent<Group> = ({ group_id, group_name, group_rank, entry_count, handleGroupLeave }) => {
  const router = useRouter();
  const [{ entry, viewingGroup }, dispatch] = useClientContext();
  const { fetchApiEndpoint } = useApiFetch();
  const { fireEvent } = useAnalytics();
  let isLeaving = false;

  const leaveGroupMutation = useGroupsMutation(() => {
    return fetchApiEndpoint(`/api/v1/pgaoaroster/groups/${group_id}/entry/${entry?.entry_id}/leave.json`, {
      method: "PUT",
    });
  });

  const handleLeaveGroup = (groupId: number) => {
    leaveGroupMutation.mutate(viewingGroup, {
      onSuccess: (data) => {
        const groups = data.group_memberships;
        const groupIdx = groups.findIndex((group) => group?.group_id === groupId);
        if (groupIdx !== -1 && Array.isArray(groups)) {
          groups?.splice(groupIdx, 1);
        }
        fireEvent({ event: "leave_group", params: `Fantasy: Leave Group ${group_name}` });
        handleGroupLeave(groups);
      },
    });
  };

  function handleGroupRoute(groupId: number) {
    if (isLeaving) handleLeaveGroup(groupId);
    else {
      dispatch(setCurrentViewingGroup({ group_id, group_name, group_rank, entry_count }));
      router.push(group_name === "Overall" ? `/leaderboard` : `/groups/${group_id}`);
    }
    isLeaving = false;
  }

  return (
    <StyledGroupRow onClick={() => handleGroupRoute(group_id)}>
      <p className="rank">{!group_rank ? `-` : group_rank}</p>
      <p className="group-name">{group_name}</p>
      <p className="user-count">{entry_count || ""}</p>
      <p className="leave">{group_name !== "Overall" && <CloseIcon onClick={() => (isLeaving = true)} />}</p>
    </StyledGroupRow>
  );
};

const GroupsList: FunctionComponent<GroupsProps> = ({ groups, handleGroupLeave }) => {
  const [groupsList, setGroupsList] = useState([]);

  useEffect(() => {
    setGroupsList(groups);
  }, [groups]);

  return (
    <>
      <GroupListHead>
        <h4 id="rank">Rank</h4>
        <h4 id="group-name">Group Name</h4>
        <h4 id="user-count"># of Users</h4>
        <h4 id="leave">&nbsp;</h4>
      </GroupListHead>
      {groupsList.length > 0 &&
        groupsList.map((group) => {
          if (group.group_name.toLowerCase() !== "overall") {
            return <GroupRow handleGroupLeave={handleGroupLeave} key={group.group_id} {...group} />;
          }
        })}
    </>
  );
};

export default GroupsList;
