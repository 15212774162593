import styled from "styled-components";
import { FONTS } from "../styles/styleUtils";

export const SponsoredSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0;
`;

export const SponsoredLogo = styled.img`
  display: flex;
  height: 113px;
  width: 201px;
  margin-top: -35px;
  justify-content: center;
  align-items: center;
`;

export const SponsoredBySection = styled.div`
  display: block;
`;

export const BroughtToYouBy = styled.div`
  color: rgb(0, 0, 0);
  font-family: ${FONTS.NUNITO};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

export const SponsorFooter = styled.div`
  display: flex;

  p {
    color: rgb(0, 0, 0);
    font-family: NunitoSans-Regular;
    font-size: 10px;
    font-weight: normal;
    height: 28px;
    letter-spacing: 0.5px;
    text-align: center;
    width: 100%;
  }
`;
