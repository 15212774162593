import styled from "styled-components";
import { palette } from "../styles/styleUtils";

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 35px;

  p {
    color: ${palette.primary1};
    font-size: 13px;
    font-weight: bold;
  }
`;

export default function Footer() {
  return (
    <FooterContainer>
      <p></p>
    </FooterContainer>
  );
}
