import styled from "styled-components";
import { IoSwapHorizontal } from "react-icons/io5";
import { breakpoints, pxToRem } from "../styles/styleUtils";

export const SwapMessage = ({ children, ref }) => {
  return (
    <SwapMessageContainer ref={ref}>
      <ContentAreaContainer>
        <IconArea>
          <IoSwapHorizontal style={{ color: "white", fontWeight: "bold" }} />
        </IconArea>
        <ContentArea>{children}</ContentArea>
      </ContentAreaContainer>
    </SwapMessageContainer>
  );
};

const SwapMessageContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0px 3px 24px 0px rgba(0, 10, 22, 0.15);
  padding: 50px 20px;
  margin-bottom: 30px;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 30px;
  }
`;

const ContentAreaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffbe00;
  padding: 10px;
  border-radius: 100px;
  margin-right: 60px;
  font-size: 40px;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 30px;
    margin-right: 15px;
  }
`;

const ContentArea = styled.div`
  max-width: 820px;
  font-size: ${pxToRem(20)};
  font-weight: bold;
  text-align: center;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }
`;
