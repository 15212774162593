import React, { useEffect, useRef, useState } from "react";

import { useRouter } from "next/router";
import styled from "styled-components";
import useApiFetch from "../hooks/useApiFetch";
import { useModal } from "../components/Modal";
import PlayerCard from "../components/Player/PlayerCard";
import { useSession } from "src/hooks/useSession";
import { GameRound } from "../constants/interfaces/game";
import { useSessionQuery } from "src/hooks/auth";
import { emptyHeadshot, getCurrentRound, getGroupLeaderboardState } from "src/utils/game";
import { ButtonGroup, SecondaryButton } from "../styles/buttons.styled";
import useEntryMutation from "src/hooks/useEntryMutation";
import { Group } from "src/hooks/useClientContext";
import Tiebreaker from "../components/Tiebreaker/Tiebreaker";
import { Button } from "../styles/buttons.styled";
import CreateGroupModal from "../components/Groups/CreateGroupModal";
import CategoryCard from "../components/CategoryCard";
import bridgeEvents from "../utils/bridgeEvents";
import { GameplayContainer, GameplayPadding } from "../components/PageLayout";
import { Player } from "../constants/interfaces/game";
import SearchInput from "../components/inputs/SearchInput";
import PerformanceHeader from "../components/PerformanceHeader";
import SignInModal from "../components/SignIn/SignInModal";
import Nav from "../components/Nav/Nav";
import useClientContext, { setCurrentRoster } from "src/hooks/useClientContext";
import { getServerSidePageProps, useRosterQuery } from "src/hooks/api";
import { getPlayersFromEntry, getGameState } from "src/utils/game";
import { GAMESTATE } from "../constants/interfaces/game";
import { FONTS, breakpoints, palette, pxToRem } from "../styles/styleUtils";
import Countdown from "../components/Countdown";
import { Region, useRegion } from "src/components/RegionProvider";
import { validateToken } from "src/hooks/auth";
import SubHeading from "../components/SubHeading";
import GroupsList from "../components/Groups/GroupsList";
import EntryModal from "../components/SignIn/EntryModal";
import * as Collapsible from "@radix-ui/react-collapsible";
import * as Select from "@radix-ui/react-select";
// import WelcomeModal from "../components/Welcome/WelcomeModel";
import { SwapMessage } from "../components/SwapMessage";
import RosterLockedModal from "../components/RosterLockedModel";
import Footer from "../components/Footer";
import useAnalytics from "../hooks/useAnalytics";
import WelcomeModal from "../components/Welcome/WelcomeModel";
// import { getHasPicks } from "src/utils/game";
// import SwitchInput from "../components/inputs/Switch";
// import { Pick } from "src/hooks/useClientContext";

export default function Index(props) {
  useSessionQuery(false);
  const [GROUPS, setGROUPS] = useState<any>(null);
  const { query } = useRouter();
  const { passwordReset } = query;
  const { data: game } = useRosterQuery();
  const { fireEvent, isWomens } = useAnalytics();
  const { showModal, modalContent } = useModal();
  const { isAuth, isLoading, saveToken } = useSession();
  const [{ entry }, dispatch] = useClientContext();
  const { fetchApiEndpoint } = useApiFetch();
  const gameState: string = getGameState(game);
  const allPlayersTeedOff: boolean = getGroupLeaderboardState(game);
  const { setRegion, setCountryCode } = useRegion();
  const [name, setName] = useState(null);
  const [playersList, setPlayersList] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryNumber, setSelectedCategoryNumber] = useState<number | null>(null);
  const [noneSelected, setNoneSelected] = useState(true);
  const [oddsView, setOddsView] = useState(false);
  const [oddsType, setOddsType] = useState("Player Name");
  const currentRound: GameRound = getCurrentRound(game);
  const categoryHeadingContainer = useRef(null);
  const swapMessageContainer = useRef(null);
  const [categories, setCategories] = useState({
    pgaChampion: {
      src: `${isWomens ? "Past PGA Champion Womens" : "Past PGA Champion"}`,
      title: `Past ${!isWomens ? "PGA" : ""} Champion`,
      categoryKey: "Past Champion",
      playerList: [],
    },
    USAPlayer: {
      src: `USA Player${isWomens ? " Womens" : ""}`,
      title: `USA Player`,
      categoryKey: "USA Player",
      playerList: [],
    },
    InternationalPlayer: {
      src: `USA Player${isWomens ? " Womens" : ""}`,
      title: "International Player",
      categoryKey: "International Player",
      playerList: [],
    },
    Corebridge: {
      src: "Corebridge Financial Team",
      title: "Corebridge Financial Team",
      categoryKey: "Corebridge",
      playerList: [],
    },
    wildcard: {
      src: "Wildcard Slot",
      title: "Wildcard Slot",
      categoryKey: "Wildcard",
      playerList: [],
    },
  });
  const [selectedRound, setSelectedRound] = useState(null);
  const [rosterState, setRosterState] = useState({
    pgaChampion: false,
    USAPlayer: false,
    InternationalPlayer: false,
    Corebridge: false,
    wildcard: false,
  });

  // All related to Swapping Wildcard Player
  const rosterCreationOpen = gameState === GAMESTATE.ROSTER_CREATION_OPEN;
  const wildcardSwappingOpen = rosterCreationOpen || (!rosterCreationOpen && currentRound?.round_number === 3);
  const [showWildcardSwapMessage, setShowWildcardSwapMessage] = useState(false);
  const [showWildcardConfirmSwapMessage, setShowWildcardConfirmSwapMessage] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | any>();
  const [confirmSwap, setConfirmSwap] = useState(false);
  const [confirmSwapType, setConfirmSwapType] = useState("");

  const scrollToConfirmMessage = () => {
    setTimeout(() => {
      bridgeEvents.scrollElementIntoView(swapMessageContainer);
    }, 200);
  };

  const tokenEventListener = () => {
    window.addEventListener(
      "message",
      (event) => {
        if (
          event.origin === "https://pgachampionship.uat.pga.golf.psdops.com" ||
          event.origin === "https://www.pgachampionship.com" ||
          event.origin === "https://www.kpmgwomenspgachampionship.com" ||
          event.origin === "https://kpmg.uat.pga.golf.psdops.com"
        ) {
          const eventData = JSON.parse(event.data);
          switch (eventData.name) {
            case "returnToken":
              saveToken(eventData.value);
              break;
            default:
              console.warn("Not implemented");
          }
        }
      },
      false,
    );

    bridgeEvents.getToken();
  };

  useEffect(() => {
    if (document.readyState === "complete") {
      tokenEventListener();
    } else {
      window.addEventListener("load", tokenEventListener);
    }
  });

  useEffect(() => {
    if (currentRound) {
      setSelectedRound(currentRound.round_number);
    }
  }, [currentRound]);

  useEffect(() => {
    if (!isAuth) {
      setRosterState({
        pgaChampion: false,
        USAPlayer: false,
        InternationalPlayer: false,
        Corebridge: false,
        wildcard: false,
      });
    }
  }, [isAuth]);

  useEffect(() => {
    if (entry) {
      if (entry && entry.picks) {
        setName(entry.name);
      } else if (isAuth && !isLoading && !entry && !entry.picks) {
        showModal(<EntryModal />);
      }
    }

    if (gameState && !isAuth) {
      if (!isAuth && !isLoading && gameState === GAMESTATE?.ROSTER_CREATION_LOCKED) {
        showModal(<RosterLockedModal />);
      }
    }

    const welcomeModalClosed = localStorage.getItem("welcomeModalClosed");
    if (!isLoading && gameState !== "roster_creation_locked" && !welcomeModalClosed) {
      showModal(<WelcomeModal />);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, game, isAuth, isLoading, gameState]);

  useEffect(() => {
    if (!modalContent) {
      fireEvent({ event: "Open", params: "Fantasy Home" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  useEffect(() => {
    if (passwordReset) {
      validateToken(passwordReset as string)
        .then((res) => {
          if (res) {
            showModal(<SignInModal origin="landing" token={passwordReset as string} isResetPassword={true} />);
          }
        })
        .catch((e) => {
          showModal(<SignInModal origin="landing" isResetPassword={false} hasErrors={e} />);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordReset]);

  useEffect(() => {
    if (entry && entry?.picks?.length > 0) {
      setSelectedRound(currentRound?.round_number);
      const playersFromEntry = getPlayersFromEntry(entry, game);
      const fetchedRosterState = rosterState;
      Object.keys(categories).forEach((category, index) => {
        if (playersFromEntry[index]) Object.assign(fetchedRosterState, { [category]: playersFromEntry[index] });
        else Object.assign(fetchedRosterState, { [category]: false });
      });
      setRosterState(fetchedRosterState);
    }

    if (isAuth && entry) {
      setGROUPS(sortGroupListByRank(entry?.group_memberships));
    }

    setCountryCode(props.countryCode);
    if (props.countryCode === "US" || props.countryCode === "CA") {
      setRegion(Region.UnitedStates);
    } else {
      setRegion(Region.Europe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, game]);

  useEffect(() => {
    let tempCategoryData = categories;

    if (game && game.players) {
      Object.keys(categories).forEach((category) => {
        const playerListPerCategory = game.players.filter((player: Player) =>
          player.categories.includes(categories[category].categoryKey),
        );
        tempCategoryData = {
          ...tempCategoryData,
          [category]: { ...tempCategoryData[category], playerList: playerListPerCategory },
        };
      });
    }

    setCategories(tempCategoryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game]);

  const mutation = useEntryMutation((data) => {
    return fetchApiEndpoint(`/api/v1/pgaoaroster/entries/${entry.entry_id}/picks.json`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  });

  const isWildcardPlayerTopTen = (player: Player) => {
    if (player.position !== null) {
      return player.position <= 10;
    }

    return false;
  };

  function handleCategorySelect(areaLabel: string) {
    const arrayOfCategories = Object.keys(categories);
    const categoryNumber = (category) => category === areaLabel;
    setSelectedCategoryNumber(arrayOfCategories.findIndex(categoryNumber));
    setSelectedCategory(areaLabel);

    setShowWildcardSwapMessage(false);
    setShowWildcardConfirmSwapMessage(false);
    setConfirmSwap(false);

    setNoneSelected(false);

    let filterPlayers;

    if (areaLabel === "wildcard" && rosterState[areaLabel]) {
      setShowWildcardSwapMessage(true);
      filterPlayers = categories[areaLabel].playerList.filter(
        (player: Player) => player.position > 10 || player.position === null,
      );
    } else {
      filterPlayers = categories[areaLabel].playerList.filter((player: Player) => player.live === false);
    }

    if (oddsView) {
      sortPlayersListByOdds(oddsType, filterPlayers);
    } else {
      setPlayersList(filterPlayers);
    }

    setTimeout(() => {
      bridgeEvents.scrollElementIntoView(categoryHeadingContainer);
    }, 200);
  }

  function handleClearCategory() {
    setSelectedCategory(null);
    setShowWildcardSwapMessage(false);
    setShowWildcardConfirmSwapMessage(false);
    setConfirmSwap(false);
    setNoneSelected(true);
  }

  function handlePlayerSelect(player: Player) {
    mutation.mutate(
      { slot_id: selectedCategoryNumber + 1, player_id: player.player_id },
      {
        onSuccess: (data) => {
          fireEvent({
            event: "swap_golfer",
            params: `slot_id: ${selectedCategoryNumber + 1}, player_id: ${player.player_id}`,
          });
          setConfirmSwap(false);
          updateWildcardPlayerRoaster(player);
          setRosterState({ ...rosterState, [selectedCategory]: player });
          dispatch(setCurrentRoster(data));
        },
        onError: (err) => {
          console.error("error: ", err);
        },
      },
    );
  }

  function handlePlayerDrop() {
    mutation.mutate(
      { slot_id: selectedCategoryNumber + 1, player_id: null },
      {
        onSuccess: (data) => {
          handleCancelSwapWildcard();
          setRosterState({ ...rosterState, [selectedCategory]: false });
          setConfirmSwap(false);
          dispatch(setCurrentRoster(data));
        },
        onError: (err) => {
          console.error("error: ", err);
        },
      },
    );
  }

  function updateReplaceRosterState(player: Player) {
    let originalCategoryLocation;
    let originalCategoryNumber;

    if (rosterState) {
      const rosterStateByCategory = Object.values(rosterState);

      Object.keys(rosterState).forEach((category) => {
        if (rosterState[category].player_id === player.player_id) {
          const categoryNumber = (category) => category?.player_id === player.player_id;
          originalCategoryNumber = rosterStateByCategory.findIndex(categoryNumber);
          originalCategoryLocation = category;
        }
      });

      setRosterState({
        ...rosterState,
        [selectedCategory]: player,
        [originalCategoryLocation]: false,
      });

      return originalCategoryNumber;
    }
  }

  function handlePlayerReplace(player: Player) {
    const originalCategoryNumber = updateReplaceRosterState(player);

    mutation.mutate(
      { slot_id: originalCategoryNumber + 1, player_id: null },
      {
        onSuccess: (data) => {
          setConfirmSwap(false);
          updateWildcardPlayerRoaster(player);
          dispatch(setCurrentRoster(data));
        },
      },
    );

    setTimeout(() => {
      mutation.mutate(
        { slot_id: selectedCategoryNumber + 1, player_id: player.player_id },
        {
          onSuccess: (data) => {
            setConfirmSwap(false);
            dispatch(setCurrentRoster(data));
          },
        },
      );
    }, 500);
  }

  function updateWildcardPlayerRoaster(player: Player) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isTopTen = isWildcardPlayerTopTen(rosterState["wildcard"]);

    if (selectedCategory === "wildcard" && rosterState.wildcard && !confirmSwap && isTopTen) {
      setShowWildcardSwapMessage(false);
      setShowWildcardConfirmSwapMessage(true);
      setConfirmSwap(true);

      setSelectedPlayer(player);
      setConfirmSwapType("replace");

      scrollToConfirmMessage();
      return;
    }
  }

  function handleConfirmSwapWildcard() {
    switch (confirmSwapType) {
      case "select":
        setShowWildcardConfirmSwapMessage(false);
        setShowWildcardSwapMessage(true);
        handlePlayerSelect(selectedPlayer);
        fireEvent({ event: "select_wildcard", params: `Selected ${selectedPlayer}` });
        break;
      case "replace":
        setShowWildcardConfirmSwapMessage(false);
        setShowWildcardSwapMessage(true);
        handlePlayerReplace(selectedPlayer);
        fireEvent({ event: "swapped_wildcard", params: `Swapped ${selectedPlayer}` });
        break;
      case "drop":
        setShowWildcardConfirmSwapMessage(false);
        setShowWildcardSwapMessage(true);
        handlePlayerDrop();
        break;

      default:
        break;
    }
  }

  function handleCancelSwapWildcard() {
    setShowWildcardConfirmSwapMessage(false);
    setShowWildcardSwapMessage(true);
    setConfirmSwap(false);
    setSelectedPlayer({});
  }

  function isPicked(playerId: number) {
    let isPicked = false;
    if (rosterState) {
      Object.keys(rosterState).forEach((category) => {
        if (rosterState[category].player_id === playerId) isPicked = true;
      });
    }
    return isPicked;
  }

  function debounce(func, timeout = 400) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  function sortByPlayerName(searchValue: string) {
    setSearchValue(searchValue);
    if (searchValue === "") setPlayersList(categories[selectedCategory].playerList);

    if (selectedCategory === "wildcard") {
      selectedRound > 1
        ? setPlayersList(
            categories[selectedCategory].playerList.filter(
              (player: Player) =>
                player.position > 10 &&
                player.first_name
                  .toLowerCase()
                  .concat(`${player.last_name.toLowerCase()}`)
                  .includes(searchValue.toLowerCase()),
            ),
          )
        : setPlayersList(
            categories[selectedCategory].playerList.filter((player: Player) =>
              player.first_name
                .toLowerCase()
                .concat(`${player.last_name.toLowerCase()}`)
                .includes(searchValue.toLowerCase()),
            ),
          );
    } else
      setPlayersList(
        categories[selectedCategory].playerList.filter((player: Player) =>
          player.first_name
            .toLowerCase()
            .concat(`${player.last_name.toLowerCase()}`)
            .includes(searchValue.toLowerCase()),
        ),
      );
  }

  function sortGroupListByRank(groupList: Group[]) {
    return groupList.sort((group1, group2) => group1.group_name !== "Overall" && group1.group_rank - group2.group_rank);
  }

  const handleGroupJoin = (newGroupList?) => {
    setGROUPS(sortGroupListByRank(newGroupList));
  };

  const handleCreateGroupModal = () => {
    if (!isAuth) {
      showModal(<SignInModal origin="CreateGroup" />);
    } else showModal(<CreateGroupModal handleGroupJoin={handleGroupJoin} handleClearCategory={handleClearCategory} />);
  };

  const sortPlayersListByOdds = (type, playersList) => {
    switch (type) {
      case "Odds To Win":
        setOddsType("Odds To Win");
        return setPlayersList(
          [...playersList]?.sort((a, b) => {
            return (
              +(a?.odds?.WINNER === undefined) - +(b?.odds?.WINNER === undefined) ||
              (typeof a?.odds?.WINNER != "undefined" && a?.odds?.WINNER[0]?.current) -
                (typeof b?.odds?.WINNER != "undefined" && b?.odds?.WINNER[0]?.current)
            );
          }),
        );
        break;
      case "Top 10":
        setOddsType("Top 10");
        return setPlayersList(
          [...playersList]?.sort((a, b) => {
            return (
              +(a?.odds?.TOP_RANK === undefined) - +(b?.odds?.TOP_RANK === undefined) ||
              (typeof a?.odds?.TOP_RANK != "undefined" &&
                a?.odds?.TOP_RANK.find((rank) => rank.name === "Top 10").current) -
                (typeof b?.odds?.TOP_RANK != "undefined" &&
                  b?.odds?.TOP_RANK.find((rank) => rank.name === "Top 10").current)
            );
          }),
        );
        break;
      case "Player Name":
        setOddsType("Player Name");
        return sortByPlayerName("");
      default:
        break;
    }
  };

  const handleGroupLeave = (newGroupList?) => {
    setGROUPS(sortGroupListByRank(newGroupList));
  };

  function isPlayerWithdrawn(player: Player) {
    let isWithdraw = false;
    player.round_stats.forEach((round) => {
      if (round.status == "withdrawn") isWithdraw = true;
    });
    return isWithdraw;
  }

  function isPlayerCut(player: Player) {
    let isCut = false;
    player.round_stats.forEach((round) => {
      if (round.status == "cut") isCut = true;
    });
    return isCut;
  }

  function playerStarted(player: Player) {
    if (player && player.round_stats) {
      return (
        player.round_stats.filter((round: GameRound) => round?.round_number === currentRound?.round_number)[0]
          ?.started === true
      );
    }
  }

  function welcomeMessageText() {
    const isPartialList = Object.keys(categories).some(
      (categoryKey) => categories[categoryKey].playerList.length === 0,
    );
    if (isPartialList) return "Select Players from the Corebridge Financial Team. Other categories coming soon!";
    else if (GAMESTATE.ROSTER_CREATION_LOCKED && !isAuth) return "Sign in to see how your All-Star lineup is doing!";
    else return "Choose a player from the following four categories to create your All-Star lineup!";
  }

  return (
    <>
      <Nav />
      <>
        {isAuth && entry ? (
          <PerformanceHeader
            name={name}
            pointsByRound={[
              { roundNumber: 1, points: parseInt(entry.round_1_pts) },
              { roundNumber: 2, points: parseInt(entry.round_2_pts) },
              { roundNumber: 3, points: parseInt(entry.round_3_pts) },
              { roundNumber: 4, points: parseInt(entry.round_4_pts) },
            ]}
          />
        ) : (
          <DescriptionTextContainer>
            <h3>{welcomeMessageText()}</h3>
          </DescriptionTextContainer>
        )}
        <GameplayContainer>
          <GameplayPadding>
            {
              <CategoriesContainer>
                {Object.keys(categories).map((category) => (
                  <CategoryCard
                    wildcardSwappingOpen={wildcardSwappingOpen}
                    key={category}
                    areaLabel={category}
                    category={category}
                    active={selectedCategory === category}
                    inactive={!noneSelected && selectedCategory !== category && !rosterState[category]}
                    title={categories[category].title}
                    onClick={() => handleCategorySelect(category)}
                    isComingSoon={categories[category]?.playerList.length === 0}
                    selectedPlayer={rosterState[category]}
                    selectedRound={selectedRound}
                    src={categories[category]?.src}
                  />
                ))}
              </CategoriesContainer>
            }
            {showWildcardConfirmSwapMessage && (
              <SwapMessage ref={swapMessageContainer}>
                You are about to swap out a golfer that is currently placed within the top 10. If you swap this player
                out, you will not be able to swap them back in.
                <SwapButtonActionContainer>
                  <SecondaryButton style={{ margin: "0 10px" }} onClick={() => handleConfirmSwapWildcard()}>
                    Confirm
                  </SecondaryButton>

                  <SecondaryButton onClick={() => handleCancelSwapWildcard()}>Cancel</SecondaryButton>
                </SwapButtonActionContainer>
              </SwapMessage>
            )}
            {showWildcardSwapMessage && (
              <SwapMessage ref={swapMessageContainer}>
                You can swap out your Wild Card golfer between Rounds 2 and 3 for any golfer not in the top 10 on the
                leaderboard at the conclusion of Round 2 who has not yet teed off in Round 3. See official rules for
                details.
              </SwapMessage>
            )}
            {entry && entry.has_made_first_five && (
              <Tiebreaker
                correctAnswer={game?.settings.tiebreaker ? game.settings.tiebreaker : 0}
                gameState={gameState}
                allPlayersTeedOff={allPlayersTeedOff}
                handleClearCategory={handleClearCategory}
              />
            )}
            {!selectedCategory && gameState === GAMESTATE.ROSTER_CREATION_OPEN ? (
              <Countdown countDownTo={game?.settings?.counts_down_to} />
            ) : (
              <>
                {(gameState === GAMESTATE.ROSTER_CREATION_OPEN || showWildcardSwapMessage) && entry ? (
                  <GameplayPickingContainer open={oddsView} onOpenChange={setOddsView}>
                    <div>
                      {selectedCategory === "teamOf20" && (
                        <p style={{ marginBottom: 30 }}>
                          Each year, 20 PGA Professionals get to compete on one of golf&apos;s grandest stages at the
                          PGA Championship. This year&apos;s &apos;Corebridge Financial Team&apos; competing are teeing
                          it up at Oak Hill Country Club in Rochester. Each of them earned their spot at the PGA
                          Professional Championship last month. Among the 20, 8 are veterans of the PGA Championship
                          while the 12 others will be making their debuts. Click here for more information -&nbsp;
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://www.pgachampionship.com/team-of-20"
                          >
                            (Corebridge Financial Team)
                          </a>
                        </p>
                      )}
                    </div>
                    <FiltersContainer>
                      <CategoryHeadingContainer ref={categoryHeadingContainer}>
                        <h1>{categories[selectedCategory]?.title}</h1>
                      </CategoryHeadingContainer>

                      <RightSide>
                        <SponsorshipContainer>
                          {/* <span>Presented By</span>{" "}
                          <img src={`/sponsor/Michelob-Ultra-Emblem.png`} alt={`Michelob Ultra Emblem`} /> */}
                        </SponsorshipContainer>

                        <div style={{ display: "flex" }}>
                          <SearchInput
                            onChange={debounce((searchValue: string) => sortByPlayerName(searchValue))}
                            placeHolder="Search by Player"
                          />
                          <Select.Root onValueChange={(value: string) => sortPlayersListByOdds(value, playersList)}>
                            <SelectTrigger area-label="Sort Players">
                              <Select.Value>{oddsType}</Select.Value>
                              <img className="caret" src={"/caret.svg"} />
                            </SelectTrigger>
                            <Select.Portal>
                              <SelectContent position="popper">
                                <Select.Viewport>
                                  <SelectItem value="Player Name">
                                    Player Name&nbsp;{oddsType == "Player Name" && <span>&#10003;</span>}
                                  </SelectItem>
                                  {/* <SelectItem value="Odds To Win">
                                Odds To Win&nbsp;{oddsType == "Odds To Win" && <span>&#10003;</span>}
                              </SelectItem> */}
                                  {selectedCategory !== "wildcard" && (
                                    <SelectItem value="Top 10">
                                      Top 10&nbsp;{oddsType == "Top 10" && <span>&#10003;</span>}
                                    </SelectItem>
                                  )}
                                </Select.Viewport>
                              </SelectContent>
                            </Select.Portal>
                          </Select.Root>
                        </div>
                      </RightSide>
                      {/* <SwitchContainer>
                        Hide Odds
                        <Collapsible.Trigger>
                          <SwitchInput checked={oddsView} />
                        </Collapsible.Trigger>
                        Show Odds
                      </SwitchContainer> */}
                    </FiltersContainer>
                    <PlayerCardGrid>
                      {playersList && playersList.length ? (
                        playersList.map((player: Player, index) => (
                          <div key={index} className="player-card-container">
                            <PlayerCard
                              isSelected={isPicked(player.player_id)}
                              name={`${player.first_name} ${player.last_name}`}
                              isWithdrawn={isPlayerWithdrawn(player)}
                              isCut={isPlayerCut(player)}
                              started={playerStarted(player)}
                              headshotUrl={player.headshot_url || emptyHeadshot()}
                            >
                              <PlayerCardButtonsContainer isSelected={isPicked(player.player_id)}>
                                {/* <Collapsible.Content>
                                  <OddsSection oddsView={oddsView}>
                                    <div>
                                      {typeof player?.odds?.WINNER != "undefined"
                                        ? `${player?.odds?.WINNER[0].current > 0 ? "+" : ""}${
                                            player?.odds?.WINNER[0]?.current
                                          }`
                                        : "TBD"}{" "}
                                      TO WIN
                                    </div>
                                    <div>
                                      {typeof player?.odds?.TOP_RANK != "undefined"
                                        ? `${player?.odds?.TOP_RANK[1].current > 0 ? "+" : ""}${
                                            player?.odds?.TOP_RANK[1]?.current
                                          }`
                                        : "TBD"}{" "}
                                      TOP 10
                                    </div>
                                  </OddsSection>
                                </Collapsible.Content> */}
                                {!isPlayerCut(player) && !isPlayerWithdrawn(player) ? (
                                  <ButtonActionsContainer>
                                    {isPicked(player.player_id) &&
                                      !playerStarted(player) &&
                                      gameState !== GAMESTATE.ROSTER_CREATION_LOCKED &&
                                      rosterState[selectedCategory].player_id === player.player_id && (
                                        <Button onClick={() => handlePlayerDrop()}>Drop</Button>
                                      )}
                                    {/* {isPicked(player.player_id) &&
                                      !playerStarted(player) &&
                                      rosterState[selectedCategory].player_id !== player.player_id && (
                                        <SecondaryButton onClick={() => handlePlayerReplace(player)}>
                                          Replace
                                        </SecondaryButton>
                                      )} */}
                                    {!isPicked(player.player_id) &&
                                      rosterState[selectedCategory].player_id &&
                                      !playerStarted(player) && (
                                        <SecondaryButton onClick={() => handlePlayerSelect(player)}>
                                          Replace
                                        </SecondaryButton>
                                      )}
                                    {!isPicked(player.player_id) &&
                                      !playerStarted(player) &&
                                      !rosterState[selectedCategory].player_id && (
                                        <SecondaryButton onClick={() => handlePlayerSelect(player)}>
                                          Choose
                                        </SecondaryButton>
                                      )}

                                    {/*Commented this out until NBC builds the player profiles*/}
                                    {/* <a
                                        style={{ marginLeft: !playerStarted(player) && 7, borderRadius: 50 }}
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`https://www.pgachampionship.com/player/${player.first_name}-${player.last_name}`}
                                      >
                                        <div className="a-text">Profile</div>
                                      </a> */}
                                  </ButtonActionsContainer>
                                ) : null}
                              </PlayerCardButtonsContainer>
                            </PlayerCard>
                          </div>
                        ))
                      ) : (
                        <NoPlayersMatch>
                          <p>No players match: &quot;{searchValue}&quot;</p>
                        </NoPlayersMatch>
                      )}
                    </PlayerCardGrid>
                  </GameplayPickingContainer>
                ) : null}
              </>
            )}
            {isAuth && entry && (
              <div style={{ marginBottom: 60 }}>
                <GroupsContainer>
                  <MyGroupsSubheadingContainer>
                    <SubHeading title="Groups" />
                    {/* {gameState === GAMESTATE.ROSTER_CREATION_LOCKED && (
                      <SponsorshipContainer>
                        <span>Presented By</span>{" "}
                        <img src={`/sponsor/Michelob-Ultra-Emblem.png`} alt={`Michelob Ultra Emblem`} />
                      </SponsorshipContainer>
                    )} */}
                  </MyGroupsSubheadingContainer>
                  {GROUPS?.length ? (
                    <GroupsList handleGroupLeave={handleGroupLeave} groups={GROUPS} />
                  ) : (
                    <p style={{ marginBottom: 50 }}>No Group Memberships</p>
                  )}
                  <ButtonGroup style={{ marginTop: 26 }}>
                    <Button onClick={() => handleCreateGroupModal()}>Create Group</Button>
                  </ButtonGroup>
                </GroupsContainer>
              </div>
            )}
            <Footer />
          </GameplayPadding>
        </GameplayContainer>
      </>
    </>
  );
}

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media screen and (max-width: ${breakpoints.mobile}) {
    grid-gap: 15px;
    justify-content: space-around;
  }
`;

const GroupsContainer = styled.div``;

const MyGroupsSubheadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const DescriptionTextContainer = styled.div`
  margin: 0 20px;
  text-align: center;
`;

const PlayerCardGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 22px;
  scroll-margin-top: 250px;

  @media (max-width: 1024px) {
    scroll-margin-top: 180px;
  }

  .player-card-container {
    width: 100%;
    @media (max-width: ${breakpoints.mobile}) {
      min-width: 150px;
    }
  }

  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-gap: 10px;
  }
`;

const CategoryHeadingContainer = styled.div`
  padding-top: 10px;
  margin-bottom: 15px;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${breakpoints.mobile}) {
    align-items: flex-start;
  }
`;

const SponsorshipContainer = styled.div`
  margin-bottom: 25px;

  span {
    color: rgb(0, 0, 0);
    font-family: ${FONTS.NUNITO};
    font-size: 14px;
    font-weight: bold;
  }
  img {
    height: 75px;
    width: 150px;
    margin-bottom: -32px;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  justify-content: center;
  align-items: start;

  label {
    width: 250px;
  }

  select {
    margin-right: 30px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    justify-content: center;
    flex-direction: column;
    width: 100%;

    select {
      margin-right: 0;
    }
  }
`;

const PlayerCardButtonsContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  text-align: center;
  flex-flow: column;
  min-height: 20px;
  justify-content: space-evenly;

  a {
    text-decoration: none;
    color: ${palette.neutral0} !important;
    font-size: 16px;
    border-radius: 4px;
    width: 100px;
    font-weight: bold;
    height: 37px;
    border: 1px solid #000;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${breakpoints.mobile}) {
      width: 65px;
      height: 30px !important;
      font-size: 11px;
    }

    .a-text {
      display: inline;
      border-radius: 50px;
    }

    @media (hover: hover) {
      :hover:not(:active) {
        color: #222;
        background-color: #f2f4f7;
      }
    }

    :active {
      background-color: #f2f4f7;
    }
  }

  button {
    font-size: 16px;
    width: 100px;
    height: 37px;
    font-weight: bold;
  }

  .selected {
    opacity: 0.5;
    cursor: initial;
  }

  @media (max-width: ${breakpoints.mobile}) {
    button {
      width: 65px;
      height: 30px !important;
      font-size: 11px;
    }
  }
`;

// const SwitchContainer = styled.div`
//   display: flex;
//   align-items: center;
//   font-size: 14px;
//   margin-right: 10px;
//   text-align: center;
// `;

// const OddsSection = styled.div<{ oddsView: boolean }>`
//   font-size: 11px;
//   height: 28px;
//   display: flex;
//   width: 200px;
//   justify-content: space-evenly;
//   align-items: center;
//   font-weight: 600;
//   animation-duration: 200ms;
//   animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
//   animation-fill-mode: forwards;
//   will-change: transform, opacity;
//   transform: translateY(-60px);
//   margin-left: auto;
//   margin-right: auto;
//   color: ${palette.primary2};

//   @media (max-width: ${breakpoints.mobile}) {
//     width: 145px;
//     font-size: 8px;
//   }

//   @keyframes slideDown {
//     0% {
//       opacity: 0;
//       transform: translateY(-1);
//     }
//     100% {
//       opacity: 1;
//       transform: translateY(0px);
//     }
//   }

//   animation-name: ${({ oddsView }) => oddsView && "slideDown"};
// `;

const SelectContent = styled(Select.Content)`
  width: 248px;
  background-color: white;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  will-change: transform, opacity;
  transform: translateY(-60px);

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-1);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &[data-state="open"] {
    animation-name: "slideDown";
  }
  @media (max-width: ${breakpoints.mobile}) {
    min-width: 150px;
    max-width: 200px;
  }
`;

const SelectItem = styled(Select.Item)`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  :not(:last-child) {
    border-bottom: 1px solid #000;
  }
  padding: 5px;
`;

const SelectTrigger = styled(Select.Trigger)`
  box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  -webkit-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  -moz-box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  background-color: white;
  border-radius: 4px;
  width: 230px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
  margin: 0 0 0 20px;
  font-size: ${pxToRem(15)};

  @media (hover: hover) {
    :hover:not(:active) {
      color: #222;
      background-color: #f2f4f7;
    }
  }

  :active {
    background-color: #f2f4f7;
  }

  .caret {
    position: relative;
    transform: rotate(90deg);
    width: 15px;
    transition: transform 0.2s ease-in-out;
  }
  &[data-state="open"] {
    .caret {
      transform: rotate(-90deg);
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    /* margin: 20px 0; */
    width: 100%;
  }
`;

const NoPlayersMatch = styled.div`
  padding-bottom: 70px;
  padding-top: 45px;

  p {
    font-weight: bold;
    position: absolute;
    left: 0;
  }
`;

const GameplayPickingContainer = styled(Collapsible.Root)`
  margin-bottom: 40px;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 14px;
  }
`;

const ButtonActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 40px;
`;

const SwapButtonActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

export const getServerSideProps = async (ctx) => {
  const pageProps = await getServerSidePageProps(ctx, false);
  return {
    props: {
      ...pageProps,
    },
  };
};
