import styled from "styled-components";
import { IoSwapHorizontal } from "react-icons/io5";
import { breakpoints } from "src/styles/styleUtils";
import useAnalytics from "../hooks/useAnalytics";

interface SwapIconContainerProps {
  colorState: string;
}

const SwapIconContainer = styled.div<SwapIconContainerProps>`
  position: absolute;
  background: ${(p) => p.colorState};
  top: -17px;
  left: -13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;

  .tooltip {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #fff;
    height: 35px;
    width: 35px;
  }

  .tooltip:before,
  .tooltip:after {
    position: absolute;
    content: "";
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: 10;
  }

  .tooltip:before {
    border-width: 20px 15px 0 15px;
    border-style: solid;
    border-color: #ebebeb transparent transparent transparent;
    top: -20px;
    z-index: 10;
  }

  .tooltip:hover:after {
    display: flex;
    justify-content: center;
    align-items: center;
    content: attr(data-tooltip);
    background: #ebebeb;
    width: 300px;
    height: 130px;
    font-size: 13px;
    color: #000000;
    font-weight: 300;
    top: -150px;
    left: -40px;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    letter-spacing: 1px;
    transform: translateY(20px);
    z-index: 10;

    @media (max-width: ${breakpoints.mobile}) {
      width: 275px;
    }
  }

  .tooltip:hover::before,
  .tooltip:hover::after {
    opacity: 1;
    transform: translateY(-2px);
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
    top: -15px;
    left: -15px;
  }
`;

export default function SwapIcon({ wildcardSwappingOpen, selectedPlayer, isLocked }) {
  const { isWomens } = useAnalytics();

  function handleColorState() {
    if (!wildcardSwappingOpen || isLocked) return "#c62020";
    else if (wildcardSwappingOpen && !selectedPlayer) return "#ffbe00";
    else if (wildcardSwappingOpen && selectedPlayer) return "#49e048";
    else return "#ffbe00";
  }

  const tooltipDescription =
    wildcardSwappingOpen && !isLocked
      ? `You now have the option to swap the golfer in your ${!isWomens ? "Michelob Ultra" : ""} Wildcard slot.`
      : `At the conclusion of Round 2, you will have the option to swap the golfer in your ${
          !isWomens ? "Michelob Ultra" : ""
        } Wildcard slot.`;

  return (
    <SwapIconContainer colorState={handleColorState()}>
      <span className="tooltip" data-tooltip={tooltipDescription}>
        <IoSwapHorizontal style={{ fontSize: "24px", color: "white", fontWeight: "bold" }} />
      </span>
    </SwapIconContainer>
  );
}
