import styled from "styled-components";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSession } from "src/hooks/useSession";
import { useModal } from "src/components/Modal";
import SignInModal from "../../components/SignIn/SignInModal";
import { Button } from "../../styles/buttons.styled";
import { breakpoints, palette, pxToRem } from "../../styles/styleUtils";

const NavContainer = styled.nav`
  margin: 0 auto;
  padding-bottom: 30px;
  margin-top: 20px;
  max-width: 1200px;
  min-width: 320px;
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.large}) {
    padding: 0 50px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 30px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 7px;
    overflow-y: scroll;
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    padding-bottom: 20px;

    @media screen and (max-width: 500px) {
      justify-content: flex-start;
    }
  }

  li {
    white-space: nowrap;
    word-wrap: nowrap;
    list-style: none;
    margin-right: 25px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    background-color: ${palette.neutral2};
    width: 181px;
    text-align: center;
    font-size: ${pxToRem(16)} !important;

    &.selected {
      color: ${palette.neutral1};
      background-color: ${palette.primary1} !important;
    }

    &:hover {
      background-color: ${palette.primary1};
      color: ${palette.neutral1};
    }
  }

  li:last-child {
    margin-right: 0;
  }
`;

const SignInButton = styled(Button)`
  margin-bottom: 30px;
`;

const SignInButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`;

export default function Nav() {
  const { showModal } = useModal();
  const router = useRouter();
  const basePath = router.pathname.split("/")[1];
  const { isAuth } = useSession();

  return (
    <>
      {!isAuth ? (
        <SignInButtonContainer>
          <SignInButton onClick={() => showModal(<SignInModal origin={"Landing"} />)}>Sign In / Sign Up</SignInButton>
        </SignInButtonContainer>
      ) : (
        <NavContainer>
          <ul>
            <Link href={isAuth ? "/" : ""} passHref>
              <li
                className={basePath === "" && "selected"}
                onClick={() => !isAuth && showModal(<SignInModal origin={"Landing"} />)}
              >
                My Picks
              </li>
            </Link>
            {/* <Link href={isAuth ? "/groups-leaderboard" : ""} passHref>
              <li
                className={basePath === "groups-leaderboard" && "selected"}
                onClick={() => !isAuth && showModal(<SignInModal origin={"Landing"} />)}
              >
                Picks Leaderboard
              </li>
            </Link> */}
            <Link href={"/leaderboard"} passHref>
              <li className={basePath === "leaderboard" && "selected"}>Leaderboard</li>
            </Link>
          </ul>
        </NavContainer>
      )}
    </>
  );
}
