import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Button, ButtonGroup } from "../../styles/buttons.styled";
import { useModal } from "../Modal";
import { ModalBody, ModalHeader } from "../Modal/StandardModal";
import { FONTS, pxToRem } from "../../styles/styleUtils";
import { WelcomeCloseButton } from "../Modal/CloseButton";

import { breakpoints } from "src/styles/styleUtils";
// import { BroughtToYouBy, SponsoredBySection, SponsoredLogo, SponsoredSection } from "../Sponsorship.styles";
import useAnalytics from "../../hooks/useAnalytics";

const StyledWelcomeModal = styled.div`
  overflow: scroll;

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
`;

const WelcomeStyledHeading = styled.h1`
  font-family: "SourceSerifPro-Black";
  font-size: ${pxToRem(50)};
  font-weight: 900;
  text-align: center;
  line-height: 61px;
  padding: ${pxToRem(20)};

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${pxToRem(30)};
    line-height: 35px;
  }
`;

const WelcomeDesktopContentSection = styled.section`
  display: block;
  z-index: 1;
  padding: ${pxToRem(30)};

  p {
    margin: 15px 0;
  }

  img {
    width: 100%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const WelcomeMobileContentSection = styled.section`
  display: block;
  z-index: 1;
  padding: ${pxToRem(30)};

  p {
    margin: 15px 0;
  }

  img {
    width: 100%;
  }
  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const ImagePlaceholder = styled.div<{ isWomen: boolean }>`
  background-image: ${(isWomen) =>
    isWomen ? 'url("/WelcomeWomensSplashDT.png")' : 'url("/sponsor/Michelob-ball-on-tee.png")'};
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 100%;
  margin-top: -140px;
  justify-content: space-around;
  align-items: center;

  h4 {
    color: rgb(0, 21, 138);
    font-size: 39px;
    font-weight: normal;
    font-family: sans-serif;
    height: 40px;
    letter-spacing: 0px;
    padding: 120px 150px 0 0;
    text-align: center;
    text-transform: uppercase;
  }
  p {
    font-family: ${FONTS.NUNITO};
    color: white;
    font-size: 12px;
    margin-top: 10px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    background-position: right;
    height: 225px;
    margin-top: -70px;

    h4 {
      font-size: 16px;
      padding: 50px 150px 40px 0;
    }

    p {
      font-size: 8px;
      text-align: center;
    }
  }
`;

const GolferScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 40px auto;
  width: 700px;
  gap: 50px;
  font-size: ${pxToRem(14)};

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    gap: 25px;
    font-size: ${pxToRem(13)};
  }
`;

const GolferScoreColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &.left {
    flex-basis: 60%;
  }

  &.right {
    flex-basis: 20%;
    font-weight: bold;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;

    &.left {
      flex-basis: 65%;
    }

    &.right {
      flex-basis: 25%;
    }

    div {
      height: 25px;
      line-height: 18px;
    }
  }
`;

const GolferScoreHeading = styled.h3`
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  margin: 15px 0;
  font-size: ${pxToRem(14)};
  font-weight: bold;
  border-bottom: 1px solid rgb(224, 224, 224);
  height: 30px;
  flex-wrap: nowrap;

  @media (max-width: ${breakpoints.mobile}) {
    display: flex;
    align-items: self-end;
    padding-bottom: 15px;
    font-size: ${pxToRem(13)};
  }
`;

const WelcomeModal: FunctionComponent = () => {
  const { dismissModal } = useModal();
  const { isWomens } = useAnalytics();

  const WelcomeParagraph = () => {
    return (
      <>
        <p>
          The world’s best are teeing it up in the Pacific Northwest at Sahalee Country Club to add their name to the
          KPMG Women’s PGA Championship Trophy, and you can join in on the competition. Complete a fantasy roster of
          five players for a chance to win prizes and prove that you’re the #1 golf fan in all the land.
        </p>
        <p>
          And with the Wildcard spot, the fun isn’t over once you’ve picked your team! This special roster slot will
          allow you to pick any player in the field prior to the start of the championship and swap them out, if you
          choose, between Rounds 2 and 3. You can follow your team’s progress throughout the championship as the action
          unfolds!
        </p>
        <p>The players you select on your roster will compile points using the following scoring system:</p>
        <GolferScoreContainer>
          <GolferScoreColumn className="left">
            <GolferScoreHeading>Golfer’s score on an individual hole</GolferScoreHeading>
            <div>
              <strong>Albatross</strong> (3 strokes under par)
            </div>
            <div>
              <strong>Eagle</strong> (2 strokes under par)
            </div>
            <div>
              <strong>Birdie</strong> (1 stroke under par)
            </div>
            <div>
              <strong>Par</strong>
            </div>
            <div>
              <strong>Bogey</strong> (1 stroke over par)
            </div>
            <div>
              <strong>Double bogey or worse</strong> (2 strokes or more over par)
            </div>
          </GolferScoreColumn>
          <GolferScoreColumn className="right">
            <GolferScoreHeading>Points Earned</GolferScoreHeading>
            <div>+10</div>
            <div>+6</div>
            <div>+3</div>
            <div>+1</div>
            <div>-1</div>
            <div>-3</div>
          </GolferScoreColumn>
        </GolferScoreContainer>
      </>
    );
  };

  // dismissModal();
  const handleCreateRoster = () => {
    localStorage.setItem("welcomeModalClosed", "true");

    dismissModal();
  };

  return (
    <StyledWelcomeModal>
      <ModalHeader hideClose={true} title="">
        <WelcomeCloseButton />
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <WelcomeStyledHeading>
          Welcome to 2024
          <br /> {isWomens ? "KPMG Women’s PGA" : "PGA"} Championship Fantasy!
        </WelcomeStyledHeading>

        {/* <SponsoredSection>
          <SponsoredBySection>
            <BroughtToYouBy>Presented By</BroughtToYouBy>
            <SponsoredLogo src={`/sponsor/Michelob-Ultra-Emblem.png`} alt={`Michelob Ultra Emblem`} />
          </SponsoredBySection>
        </SponsoredSection> */}

        <WelcomeDesktopContentSection>
          <WelcomeParagraph />
          {isWomens ? (
            <img src={`/WelcomeCategoriesWomensDT.png`} alt={`Categories`} />
          ) : (
            <img src={`/WelcomeCategoriesDT.png`} alt={`Categories`} />
          )}
        </WelcomeDesktopContentSection>

        <WelcomeMobileContentSection>
          <WelcomeParagraph />
          {isWomens ? (
            <img src={`/WelcomeCategoriesWomensMob.png`} alt={`Categories`} />
          ) : (
            <img src={`/WelcomeCategoriesMob.png`} alt={`Categories`} />
          )}
        </WelcomeMobileContentSection>

        <ImagePlaceholder isWomen={isWomens}>
          <div>
            <ButtonGroup>
              <Button
                style={{ textTransform: "uppercase", fontSize: "16px", width: "243px" }}
                type="submit"
                onClick={() => handleCreateRoster()}
              >
                Create a Roster
              </Button>
            </ButtonGroup>
            <p></p>
          </div>
        </ImagePlaceholder>
      </ModalBody>
    </StyledWelcomeModal>
  );
};

export default WelcomeModal;
