import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Button, ButtonGroup } from "../styles/buttons.styled";
import { useModal } from "./Modal";
import { ModalBody } from "./Modal/StandardModal";
import { pxToRem } from "../styles/styleUtils";

import { breakpoints } from "src/styles/styleUtils";
import // BroughtToYouBy,
// SponsorFooter,
// SponsoredBySection,
// SponsoredLogo,
// SponsoredSection,
"./Sponsorship.styles";
import useAnalytics from "../hooks/useAnalytics";

const StyledRosterLockedModal = styled.div`
  overflow: scroll;
  padding: ${pxToRem(20)};

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
`;

const RosterLockedStyledHeading = styled.h1`
  font-family: "SourceSerifPro-Black";
  font-size: ${pxToRem(50)};
  font-weight: 900;
  text-align: center;
  line-height: 61px;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${pxToRem(30)};
    line-height: 35px;
  }
`;

const RosterLockedContentSection = styled.section`
  display: block;
  text-align: center;

  .locked-message {
    margin: 15px 0;
    color: rgb(255, 0, 0);
    font-size: 30px;
    font-weight: 900;
    height: 41px;
    text-transform: uppercase;
    margin: 0 0 40px 0;
  }
`;

const RosterLockedModal: FunctionComponent = () => {
  const { dismissModal } = useModal();
  const { isWomens } = useAnalytics();

  const handleCreateRoster = () => {
    dismissModal();
  };

  return (
    <StyledRosterLockedModal>
      <ModalBody style={{ paddingTop: 0 }}>
        <RosterLockedStyledHeading>
          Welcome to 2024
          <br /> {isWomens ? "KPMG Women's PGA" : "PGA"} Championship Fantasy!
        </RosterLockedStyledHeading>

        {/* <SponsoredSection>
          <SponsoredBySection>
            <BroughtToYouBy>Presented By</BroughtToYouBy>
            <SponsoredLogo src={`/sponsor/Michelob-Ultra-Emblem.png`} alt={`Michelob Ultra Emblem`} />
          </SponsoredBySection>
        </SponsoredSection> */}

        <RosterLockedContentSection>
          <p className="locked-message">PICKS HAVE LOCKED</p>
          <p>Sign in to view your saved picks or to view the leaderboard.</p>
        </RosterLockedContentSection>

        <ButtonGroup style={{ marginTop: 35, marginBottom: 15 }}>
          <Button
            style={{ textTransform: "uppercase", fontSize: "16px", width: "243px" }}
            type="submit"
            onClick={() => handleCreateRoster()}
          >
            Sign In
          </Button>
        </ButtonGroup>
      </ModalBody>
      {/* <SponsorFooter>
        <p></p>
      </SponsorFooter> */}
    </StyledRosterLockedModal>
  );
};

export default RosterLockedModal;
